#UserAgent
_ua = do ->
  IE = document.uniqueID
  ltIE6 = typeof window.addEventListener is undefined and typeof document.documentElement.style.maxHeight is undefined
  ltIE7 = typeof window.addEventListener is undefined and typeof document.querySelectorAll is undefined
  ltIE8 = typeof window.addEventListener is undefined and typeof document.getElementsByClassName is undefined
  ltIE9 = IE and typeof window.Worker is undefined
  IE6 = IE and ltIE6
  IE7 = IE and ltIE7 and !ltIE6
  IE8 = IE and ltIE8 and !ltIE7 and !ltIE6
  IE9 = IE and ltIE9 and !ltIE8 and !ltIE7 and !ltIE6
  IE10 = IE and !ltIE9 and !ltIE8 and !ltIE7 and !ltIE6
  Webkit = !document.uniqueID and !window.opera and !window.sidebar and !window.orientation and window.localStorage
  Safari = Webkit and navigator.vendor.search(/apple/i) isnt -1
  Chrome = Webkit and navigator.vendor.search(/google/i) isnt -1

  return {
    IE:IE,
    ltIE6:ltIE6,
    ltIE7:ltIE7,
    ltIE8:ltIE8,
    ltIE9:ltIE9,
    IE6:IE6,
    IE7:IE7,
    IE8:IE8,
    IE9:IE9,
    IE10:IE10,
    Firefox:window.sidebar,
    Opera:window.opera,
    Webkit:Webkit,
    Safari:Safari,
    Chrome:Chrome,
    Mobile:window.orientation
  }

#URL
url = do ->
  href = location.href.split '/'

  localRegex = /^\d+\.\d+\.\d+\.\d+/
  workRegex = /^.*\/pc\/[^\/]+\/.*$/

  for val , i in href
    if val == '' or i == href.length - 1 and val.indexOf '.'
      href.splice(i,1)

  if localRegex.test(location.hostname) is true or location.hostname.indexOf('localhost') isnt -1
    length = 2

  else if workRegex.test(location.href) is true
    length = 3

  else
    length = 1

  path = ''

  for j in [0..(length)]
    path += href[j]

    if j == 0
      path += '//'

    else
      path += '/'

  return path

#スムーススクロール関数モーション定義
jQuery.extend(
  jQuery.easing,
    easeInOutCirc:
      (x, t, b, c, d) ->
        if (t/=d/2) < 1
          return -c/2 * (Math.sqrt(1 - t*t) - 1) + b
        c/2 * (Math.sqrt(1 - (t-=2)*t) + 1) + b
)

$ ->
  $id = $('body').attr('id')
  $class = $('body').attr('class')

$ ->
  topBtn = $("#pagetop")

  #最初はボタンを隠す
  topBtn.hide()

  #スクロールが300に達したらボタンを表示させる
  $(window).scroll ->
    if $(this).scrollTop() > 300
      topBtn.fadeIn()
      $("#pagetop").css
        position: "fixed"
        right: "5px"
        zIndex: "1000"

    else
      topBtn.fadeOut()
    return


  #スクロールしてトップに戻る
  #500の数字を大きくするとスクロール速度が遅くなる
  topBtn.click ->
    $("body,html").animate
      scrollTop: 0
    , 500
    false

  return

$(window).load ->
  $("a.menu,.nav-close").click ->
    $(".drawer").toggleClass "active"
    $(".drawer").slideToggle()
    $(".drawer2").slideUp()
    false

  return

$(window).load ->
  $("a.menu2,.nav-close2").click ->
    $(".drawer2").toggleClass "active"
    $(".drawer2").slideToggle()
    $(".drawer").slideUp()
    false

  return

$ ->
  $("#footer .clinic-info").each ->
    $("#footer .clinic-info .clinic-item.tokyo dt").addClass("is-open")
    accordion = $(this)
    $(this).find(".clinic-item dt").click ->
      targetContentWrap = $(this).next("dd")
      if targetContentWrap.css("display") is "none"
        accordion.find("dd").slideUp()
        accordion.find(".clinic-item dt.is-open").removeClass "is-open"
      targetContentWrap.slideToggle()
      $(this).toggleClass "is-open"
      return

    return

  return

$ ->
  $(".toggle").click ->
    if $("+.a-detail", this).css("display") is "none"
      $(this).addClass "is-open"
      $("+.a-detail", this).slideDown "normal"
    else
      $(this).removeClass "is-open"
      $("+.a-detail", this).slideUp "normal"
    return

  return

#フローティングメニュー
$ ->
  if $('.banner-slide-list').length isnt 0
    $('.banner-slide-list').slick
      autoplay: false
      centerMode: true
      slidesToShow: 1
      speed: 1000
      dots: true
      centerPadding:'20px'

  $("#pagetop").after( $(".f-contact-box").clone( false ).addClass("flow-contact-box") );
  nav = $(".flow-contact-box")
  if nav.length > 0
    navTop = nav.offset().top + 500
    navHeight = nav.height() + 10
    nav.css "top", -navHeight + "px"

  showFlag = false
  #ナビゲーションの位置まできたら表示
  $(window).scroll ->
    winTop = $(this).scrollTop()
    if winTop >= navTop
      if showFlag is false
        showFlag = true
        nav.addClass("fixed").stop().animate
          top: "0px"
        , 500
    else if winTop <= navTop
      if showFlag
        showFlag = false
        nav.stop().animate
          top: -navHeight + "px"
        , 200, ->
          nav.removeClass "fixed"
          return

    return

  return

